.SignIn-module_account-title__185BK,
.ForgotPassword-module_account-title__QiF2r,
.ResetPassword-module_account-title__3RVwg {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 5px;
}

.SignIn-module_account-logo-accent__1w4UZ,
.ForgotPassword-module_account-logo-accent__NdjBD,
.ResetPassword-module_account-logo-accent__iS-dA {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 5px;
}
.SignIn-module_account-card__2mrj8{
  max-width: 400px;
}
