.layout-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.layout-wrapper .bar-layout {
  background-color: #f6f6f6;
  padding: 0px 0px !important;
  height: 50px;
  flex-wrap: nowrap;
}

.layout-wrapper .bar-layout .bar-logo{
  height: 73px;            
}

.layout-wrapper .bar-layout .title {
  line-height: 50px;
}

.layout-wrapper .topbar__logo {
  margin: -20px;
  padding-left: 20px;
}

.layout-wrapper .page-title-wrapper {
  display: flex;
  align-items: center;
  color: #656565;
  margin-left: 5px;
  max-height: 40px;
}

.layout-wrapper .k-menu-expand-arrow {
  display: none;
}

.content-layout {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  flex: 1 1;
  overflow: auto;
}

.menu {
  width: 50px;
  background-color: #f6f6f6;
}

.menu > .k-menu-vertical > .k-menu-item:not(.selected):hover {
  width: 100%;
  background-color: #cfcfcf;
}

.menu > .k-menu-vertical > .k-menu-item > .k-menu-link {
  padding: 2px 4px;
  font-size: 14px;
}

.menu > .k-menu-vertical > .k-menu-item > .k-menu-link > .k-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 18px;
  width: 42px;
  height: 36px;
  color: #424242;
}

.menu > .k-menu-vertical > .k-menu-item > .k-menu-link > .k-menu-expand-arrow.k-i-arrow-60-right {
  display: none;
}

.menu > .k-menu-vertical > .k-menu-item > .selected > .k-menu-link > .k-icon {
  color: white;
}

.menu > .k-menu-vertical > .selected > .k-menu-link > .k-icon {
  color: white;
}

.selected {
  background-color: #70bbfd;
}

.k-menu-popup > .k-menu-group > .k-menu-item > .k-menu-link > .k-icon {
  margin-right: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  .layout-wrapper .bar-layout .title {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .layout-wrapper .bar-layout .bar-title {
      display: none;
  }
}

.content-layout .pane-content { 
  height: calc(100% - 25px);
}

.content-layout .pane-content-bottom{
  height: 25px;
  background-color: #f6f6f6;  
  padding: 2px;
}
