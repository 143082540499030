@font-face {
  font-family: 'FontText';
  src: url('./fonts/tt_supermolot_neue_regular.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'FontText';
  src: url('./fonts/tt_supermolot_neue_bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'FontText';
  src: url('./fonts/tt_supermolot_neue_light.woff2') format('woff2');
  font-weight: lighter;
}

@font-face {
  font-family: 'FontDisplay';
  src: url('./fonts/tt_supermolot_neue_expanded_bold.woff2') format('woff2');
}

.wsbk-theme-style{
  .layout-wrapper .bar-layout .bar-logo{
    height: 35px;
    margin-left: 15px;
  }
  
  .layout-wrapper .bar-layout{
    background-color: #000610;
    color: white;
  }
  
  .SignIn-module_account-logo-title__3q_9g {
    position: relative;
    top: -100px;
    width: 250px;
  }
  
  .SignIn-module_account-layout__11uCv{
    background-color: #000610;
  }
  
  .SignIn-module_account-card__2mrj8{
    padding: 30px 40px;
  }
}
