.k-window-content.k-dialog-content > div {
  margin: 10px 10px 10px 0px;
}

.k-window-content.k-dialog-content .btn-action-add{
  margin: 10px 10px 10px 0px;
  width: 90px;
}

.k-animation-container.k-animation-container-relative{
  z-index: 99999 !important;
}
